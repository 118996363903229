import React, { useCallback, useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
	Box,
	Link,
	Container,
	HStack,
	Image,
	Heading,
	Text,
	Flex,
	useDisclosure,
	Alert,
	AlertIcon,
} from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import PreviewButton from '../../atoms/nav-controls/PreviewButton'
import FinalizeButton from '../../atoms/nav-controls/FinalizeButton'
import { Helmet } from 'react-helmet'
import NavBar from '../../atoms/NavBar'
import PreviewDefault from './Default'
import PreviewPending from './Pending'
import PreviewComplete from './Complete'
import { addRenderJob } from '../../../../api/admin/events'
import { useEventContext } from '../../../contexts/EventProvider'
import { useDispatch } from 'react-redux'
import { logCustomEvent } from '@vidday/utils'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import ModalPreview from '../../organisms/ModalPreview'
import useRenderJobs from '../../../hooks/useRenderJobs'
import { useVideoDurationCalc } from '../../../hooks/useVideoDurationCalc'
import { useMedia } from '../../../hooks/useMedia'
import Carousel from '../../molecules/Carousel'

let featuresData = [
	{
		path: '/assets/images/graphics/vidday-preview-feature-hd',
		content: () => (
			<>
				<Text variant="title">Professional Quality Guaranteed</Text>
				<Text pt=".25rem">
					Your final video will be delivered watermark-free, featuring audio normalization, stunning 1080p HD
					resolution, and an elegant digital gift wrap.
				</Text>
			</>
		),
		isExternal: true,
		href: 'https://help.vidday.com/what-features-do-finalized-video-gifts-get/',
		variant: 'thin',
	},
	{
		path: '/assets/images/graphics/vidday-preview-feature-cost',
		imgTransform: 'translate(1rem, 0)',
		content: () => (
			<>
				<Text variant="title">Curious About Cost?</Text>
				<Text pt=".25rem">Pricing is based on the length — you can rest assured with our price cap.</Text>
			</>
		),
		isExternal: true,
		href: 'https://www.vidday.com/pricing',
		variant: 'thin',
	},
	{
		path: '/assets/images/graphics/vidday-preview-feature-qc',
		imgTransform: 'translate(1rem, 0)',
		content: () => (
			<>
				<Text variant="title">Get a Quality Check</Text>
				<Text pt=".25rem">
					Add a Quality Check at checkout, and a specialist will review and edit your media.
				</Text>
			</>
		),
		isExternal: true,
		href: 'https://help.vidday.com/whats-the-quality-check/',
		variant: 'thin',
	},
	{
		path: '/assets/images/graphics/vidday-preview-feature-plants',
		imgTransform: 'translate(1rem, 0)',
		content: () => (
			<>
				<Text variant="title">Planting Trees Worldwide</Text>
				<Text pt=".25rem">
					With over 100,000 trees already planted, VidDay donates a portion of profits to reforestation.
				</Text>
			</>
		),
		isExternal: true,
		href: 'https://www.vidday.com/post/vidday-has-partnered-with-one-tree-planted',
		variant: 'thin',
	},
]

const usePreviewModals = () => {
	const preview = useDisclosure()
	const preflight = useDisclosure()

	return {
		preview,
		preflight,
	}
}

const useEventPreview = () => {
	const { event } = useEventContext()
	const dispatch = useDispatch()
	const renderJobs = useRenderJobs()

	/** Modal control */
	const modals = usePreviewModals()

	/** Latest render job entry */
	const [latestJob, setLatestJob] = useState(null)
	const [previewJobsCount, setPreviewJobsCount] = useState(0)

	const isFirstPreview = previewJobsCount == 0 || (previewJobsCount == 1 && latestJob?.jobState != 'finished')

	useEffect(() => {
		if (renderJobs && renderJobs.length > 0) {
			const filteredJobs = renderJobs.filter((j) => {
				return j.preview == true && j.jobState !== 'failed'
			})
			const orderedJobs = filteredJobs
				.slice()
				.sort(
					(a, b) =>
						parseInt((new Date(b.createdAt).getTime() / 1000).toFixed(0)) -
						parseInt((new Date(a.createdAt).getTime() / 1000).toFixed(0))
				)

			setLatestJob(orderedJobs[0])
			setPreviewJobsCount(orderedJobs.length)
		}
	}, [renderJobs])

	const dispatchPreview = useCallback(() => {
		let postData = { uuid: event.uuid, preview: true, template: event.themeTemplateId }
		logCustomEvent({
			action: 'previews',
			category: 'engagement',
			label: 'RenderPreview',
			identifier: event.uuid,
		})
		// dispatch first preview
		dispatch(addRenderJob(postData))
	}, [event])

	/**
	 * Start a new preview (display modal)
	 */
	const handleStartPreview = useCallback(() => {
		/** If the current count is 0 and there's no media, prevent preview and recommend theme */
		if (previewJobsCount == 0 && event.mediaSortingOrder.length == 0) {
			modals.preview.onOpen()

			/** If the current count is 0, trigger new preview immediately */
		} else if (previewJobsCount == 0) {
			dispatchPreview()
			modals.preview.onOpen() //setShowPreviewModal(true)

			/** Otherwise, trigger the modal only */
		} else if (previewJobsCount > 0 && latestJob && latestJob.jobState === 'finished') {
			modals.preview.onOpen()
		}
	}, [event, previewJobsCount, latestJob])

	return {
		latestJob,
		previewJobsCount,
		isFirstPreview,
		modals: modals,
		// {
		// 	// showPreviewModal,
		// 	// setShowPreviewModal,
		// 	// showPreflightModal,
		// 	// setShowPreflightModal,
		// },
		actions: {
			dispatchPreview,
		},
		handlers: {
			handleStartPreview,
		},
	}
}

const Preview = () => {
	const { event } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const media = useMedia()
	const navigate = useNavigate()

	const { renderTime, durationSec } = useVideoDurationCalc(media, event.themeTemplateId)

	const noMedia = event?.mediaSortingOrder?.length == 0 ? true : false

	const {
		latestJob,
		previewJobsCount,
		isFirstPreview,
		modals,
		handlers: { handleStartPreview },
		actions: { dispatchPreview },
	} = useEventPreview()

	const render = () => {
		if (latestJob) {
			if (latestJob.jobState == 'finished') {
				return (
					<PreviewComplete
						previewJobsCount={previewJobsCount}
						handleStartPreview={handleStartPreview}
						preview={latestJob}
					/>
				)
			} else {
				return (
					<>
						<PreviewPending renderTime={renderTime} durationSec={durationSec} />
					</>
				)
			}
		} else {
			return <PreviewDefault handleStartPreview={handleStartPreview} renderTime={renderTime} />
		}
	}

	return (
		<>
			<Helmet>
				<title>VidDay - Preview</title>
			</Helmet>

			{/**
			 * Modals
			 */}
			<ModalPreview
				isOpen={modals.preview.isOpen}
				onClose={modals.preview.onClose}
				dispatchPreview={dispatchPreview}
				previewJobsCount={previewJobsCount}
				isFirstPreview={isFirstPreview}
				renderTime={renderTime}
				noMedia={noMedia}
			/>

			<NavBar>
				<HStack spacing={['4%', '1rem']}>
					<BackButton onClick={() => navigate(handlers.root)} />
					{/* <BackButton onClick={handlers.root} /> */}
				</HStack>
				<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
					{(!latestJob || (latestJob && latestJob.jobState == 'finished')) && (
						<PreviewButton onClick={handleStartPreview} />
					)}
					{media.length != 0 && <FinalizeButton onClick={() => navigate(handlers.checkout)} />}
				</HStack>
			</NavBar>

			<Container maxW="container.xl" variant="main">
				<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Preview
					</Heading>

					<Text>Render as many previews until you&apos;re satisfied.</Text>
				</Box>

				{render()}

				{latestJob && latestJob.jobState == 'finished' && (
					<Alert status="warning" borderRadius="base" mb="2rem">
						<AlertIcon />
						<Text>
							Render another to preview changes made after the time above.{' '}
							<Link onClick={handleStartPreview} fontWeight="600" color="var(--vidday-colors-link)">
								Make a New Preview
							</Link>
						</Text>
					</Alert>
				)}

				{latestJob && <Carousel collection={featuresData} infinite />}
			</Container>

			<Container maxW="container.xl" py="1rem">
				<Flex direction="column" alignItems="center">
					<Link as={RouterLink} to="/" mb="0.5rem">
						<Image src="/assets/images/graphics/vidday-logo-footer.svg" />
					</Link>
					<Text fontSize="0.625rem">
						© 2015-{new Date().getFullYear()} VidDay Media Inc. All Rights Reserved.
					</Text>
				</Flex>
			</Container>
		</>
	)
}

export default Preview
