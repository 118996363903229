import React, { useEffect } from 'react'
import Card from '../../atoms/Card'
import { VStack, Image, Heading, Text } from '@chakra-ui/react'

const Pending = ({ renderTime, durationSec }) => {
	const refreshPageIn = durationSec * 1000 > 300000 ? durationSec * 1000 : 300000 // in ms

	useEffect(() => {
		const timer = setTimeout(() => {
			document.location.reload()
		}, refreshPageIn)
		return () => clearTimeout(timer)
	}, [])

	return (
		<Card
			px="1rem"
			py="2rem"
			mb="2rem"
			alignItems="center"
			variant="main"
			textAlign="center"
			mx={['1rem', 0]}
			borderRadius={['xl']}>
			<VStack spacing="1rem">
				<Image src="/assets/images/graphics/event-preview-rendering.svg" alt="VidDay Processing" />

				<Heading as="h3" size="lg" variant="hero">
					Rendering…
				</Heading>
				<Text>
					This will take about <strong>{renderTime}</strong> — you&apos;ll get an email notification when
					it&apos;s ready.
				</Text>
			</VStack>
		</Card>
	)
}

export default Pending
