import React from 'react'
import Card from '../../atoms/Card'
import { VStack, Image, Heading, Text, Flex, Button } from '@chakra-ui/react'

const Default = ({ handleStartPreview, renderTime }) => {
	return (
		<Card px="1rem" py="2rem" mb="2rem" alignItems="center" variant="main" mx={['1rem', 0]} borderRadius={['xl']}>
			<VStack spacing="1rem">
				<Image src="/assets/images/graphics/event-preview-default.svg" alt="VidDay Preview" />

				<Heading as="h3" size="lg" variant="hero">
					Make your first preview.
				</Heading>
				<Text textAlign="center">
					Rendering will take an <strong>estimated {renderTime}</strong>.
				</Text>
				<Flex justify="center">
					<Button onClick={handleStartPreview}>Make a Preview</Button>
				</Flex>
			</VStack>
		</Card>
	)
}

export default Default
