import React, { useRef } from 'react'
import Card from '../../atoms/Card'
import { Text, Flex, Button } from '@chakra-ui/react'
import ViddayVideoPlayer from '../../organisms/VideoPlayer'

const VideoElement = ({ preview }) => {
	/** Retrieve Source URL  */
	let cacheBustedSrc = `${preview?.signedOutputUrl}`
	/** video element reference */
	const videoRef = useRef()
	// could toss a modal at this point
	// let onEnded = () => {}

	return (
		<ViddayVideoPlayer
			// play={play}
			cacheBustedSrc={cacheBustedSrc}
			// data={preview}
			sources={[
				{
					src: cacheBustedSrc,
					type: 'video/mp4',
					size: '540',
				},
			]}
			videoRef={videoRef}
			// poster={poster}
			// currentRotation={currentRotation}
			// timeRange={timeRange}
			// duration={data.duration}
			// muted={muted}
			// onMuteAudio={onMuteAudio}
			// onEnded={onEnded}
			nativeControls={true}
			nativePlayer={true}
			// showTrimmer
			hideVolume={true}
			// hideFullScreen={true}
			// hideLoop={true}
			maxHeight={true}
			maxWidth={true}
			volume={1}
		/>
	)
}

// const testRenderJob = {
// 	id: '1c570a50-6d72-4dfc-bc02-8e081dc60a80',
// 	jobUid: '172095',
// 	preview: true,
// 	eventUuid: 'm-40py4z',
// 	jobState: 'finished',
// 	state: 'finished',
// 	progress: null,
// 	outputKey: 'm-40py4z/published/a636d80f3bbac214a0fdb47fe09f6217.mp4',
// 	signedOutputUrl: 'https://media.vidday.com/m-40py4z/published/a636d80f3bbac214a0fdb47fe09f6217.mp4?t=1652467037153&Expires=1655096783160&Key-Pair-Id=KXQUHJBCGP56R&Signature=F2-XePF3YT0qX3~1Xn8v6d1HPtCk5XlFXbU2uebEXflxgQ2PmWDwd1QiqPufqmdhD0XL94Xl6xvHJN3RZhMpEolnBLIG7eeZezw9TqaMrNYXa--IHSJMPJ5cjf8mfRExADB~vIDAc8DjMqq0yBYGmCQaf0WZR~NsRhgAOmIJANYDxeU2I1h16UjSb9j1Tokk~bK5nDr8niY6mqw2wFvosInTBMxApSIHf07ntlYR-zpLQgdf65W7xJCDk2ql0ew43B2mWSD0lDVAHfI~Q3qnIDBC63sSffmKv5dWKp8Mapg4ov9Bot9AzFPDAVBgpkh9EHp5LD0ctpTpkrSSBNVmEw__',
// 	thumbnail: 'm-40py4z/publisheda636d80f3bbac214a0fdb47fe09f6217_thumbnail.jpg',
// 	createdAt: '2022-05-13T18:36:58.881Z',
// 	updatedAt: '2022-05-13T18:37:17.153Z'
// }

const Complete = ({ handleStartPreview, preview, previewJobsCount }) => {
	var date = new Date(preview?.createdAt)

	return (
		<Card
			px="1rem"
			py="2rem"
			mb="2rem"
			alignItems="center"
			variant="main"
			textAlign="center"
			mx={['1rem', 0]}
			borderRadius={['xl']}>
			<VideoElement preview={preview} />

			<Text mt="1rem">
				<strong>Preview {previewJobsCount}</strong> made on{' '}
				{date !== 'Invalid Date' ? (
					<>
						<strong>
							{date.toLocaleDateString('en-US', {
								weekday: 'short',
								month: 'long',
								day: 'numeric',
							})}
						</strong>{' '}
						at <strong>{date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</strong>
					</>
				) : (
					'unknown date'
				)}
			</Text>

			<Flex mt="1rem">
				<Button onClick={handleStartPreview}>Make a new Preview</Button>
			</Flex>
		</Card>
	)
}

export default Complete
