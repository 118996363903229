import React from 'react'
import { Button } from '@chakra-ui/react'
import { MovieFilterRounded } from '../Icon'
const FinalizeButton = ({ onClick, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<MovieFilterRounded />
			Finalize
		</Button>
	)
}

export default FinalizeButton
